import React from "react"

import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import SEO from "../components/seo"
import StyledCard from "../components/StyledCard"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Slider from "@material-ui/core/Slider"

import styled from "styled-components"
import { createGlobalStyle } from "styled-components"

import Apercu from "../fonts/apercu/Apercu.ttf"
import ApercuBold from "../fonts/apercu/Apercu-Bold.otf"

import ReactGA from 'react-ga'
ReactGA.initialize('UA-120656560-3')
if (typeof(window) !== 'undefined')
	ReactGA.pageview(window.location.pathname + window.location.search)

function encode(data) {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&')
}

export default function App({ className }) {
	const [state, setState] = React.useState({})

	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value })
	}
	
	return (
		<StaticQuery
			query={backgroundImageQuery}
			render={data => {
				return (
					<StyledBackgroundImage
						Tag="section"
						className={className}
						fluid={data.desktop.childImageSharp.fluid}
						backgroundColor={`#040e18`}
					>
						<GlobalStyles />
						<SEO title="App" />
						<br />
						<StyledCard>
							<h2>Test your message</h2>

							<TextField
								label="Message"
								className={"test"}
								name="message"
								value={state.message}
								onChange={handleChange}
								margin="normal"
							/>

							<br />
							<br />

							<h4>
								Set an age range:
							</h4>
							<Slider
								value={state.age_range}
								onChange={handleChange}
								name="age_range"
								valueLabelDisplay="auto"
								aria-labelledby="range-slider"
								defaultValue={[18,35]}
								min={13}
								max={85}
							/>

							<Button
								variant="outlined"
								onClick={() => fetch("/.netlify/functions/twilio-send-sms", {
									method: "post",
									headers : { 
										"Content-Type": "application/json",
										"Accept": "application/json"
									},
									body: encode({ "test": "contact", ...state })
								})
			  						.then(response => response.json())
									.then(console.log)
								}
							>
								Send
							</Button>
						</StyledCard>
						<br />
					</StyledBackgroundImage>
				)
			}}
		/>
	)
}

const backgroundImageQuery = graphql`
	query {
		desktop: file(relativePath: { eq: "funk-milk.png" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1920) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`

const GlobalStyles = createGlobalStyle`
	@font-face {
		font-family: "Apercu";
		src: url(${Apercu});
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: "Apercu-Bold";
		src: url(${ApercuBold});
		font-weight: bold;
		font-style: normal;
	}

	body {
		margin: 0;
	}
`

const StyledBackgroundImage = styled(BackgroundImage)`
	width: 100%;
	background-position: center;
	background-repeat: repeat-y;
	background-size: cover;
	min-height: 100vh;
`
